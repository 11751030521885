/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Landing from 'views/Landing';
import DocsLayout from 'layouts/DocsLayout';
import WithLayout from 'WithLayout';

const IndexPage = () => <WithLayout component={Landing} layout={DocsLayout} />;

export default IndexPage;
